export type FormatString =
  | "numeric"
  | "2-digit"
  | "long"
  | "short"
  | "narrow"
  | undefined;

export const toDDMMYYYY = (str: string, format?: FormatString) => {
  try {
    str = str.endsWith("Z") ? str : str + "Z";
    const date = new Date(str);
    return date.toLocaleDateString("id", {
      year: "numeric",
      month: format ? format : "long",
      day: "numeric",
    });
  } catch (err) {
    return "";
  }
};

export const toDDMMYYYYHHMM = (
  str: string,
  format?: FormatString,
  useComma?: boolean,
  useWeekdays?: boolean
) => {
  try {
    let comma = useComma == false ? useComma : true;

    str = str.endsWith("Z") ? str : str + "Z";
    const date = new Date(str);

    let objectDate = {
      year: "numeric",
      month: format ? format : "short",
      day: "numeric",
    };

    if (useWeekdays) {
      //@ts-ignore
      objectDate.weekday = "long";
    }

    return (
      //@ts-ignore
      date.toLocaleDateString("id", objectDate) +
      (comma ? ", " : " ") +
      date.toLocaleTimeString("id", {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
  } catch (err) {
    return "";
  }
};

export const toDuration = (minute: number, isShort: boolean = true) => {
  const minuteString = isShort ? "m" : " menit";
  const hourString = isShort ? "j" : " jam";

  let y = minute % 3600;
  let hours = Math.floor(minute / 60);
  let minutes = y / 60;
  let seconds = y % 60;

  if (minute < 60) {
    return minute + minuteString;
  }

  if (!isShort) {
    return `${
      hours !== 0
        ? `${hours} jam ${Math.floor(minutes)} menit`
        : `${minute} menit`
    } ${Math.floor(seconds)} detik`;
  } else {
    return Math.floor(minute / 60) + hourString + minute
      ? " " + minute + minuteString
      : "";
  }
};

export const toTime = (second: number) => {
  const secondInt = ~~second;
  const pad = (n: number) => (n < 10 ? `0${n}` : `${n}`);
  if (secondInt >= 3600) {
    return `${~~(secondInt / 3600)}:${pad(~~(~~(secondInt % 3600) / 60))}:${pad(
      secondInt % 60
    )}`;
  } else if (secondInt >= 60) {
    return `${~~(secondInt / 60)}:${pad(secondInt % 60)}`;
  } else {
    return `0:${pad(secondInt)}`;
  }
};
