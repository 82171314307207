import { Course, PopularCourse, Searches } from "components/Card/interfaces";
import createStore from "zustand";

export type SearchStore = {
  // state
  currentRecommend: Course[];
  totalFilter: number;
  popularCourse: PopularCourse[];
  searches: Searches[];
  keywords: string[];
  indexName: string;

  // actions
  setRecommendData: (items: Course[]) => void;
  setTotalFilter: (filter: number) => void;
  setPopularCourse: (items: PopularCourse[]) => void;
  setSearches: (items: Searches[]) => void;
  setKeywords: (items: string[]) => void;
  setIndexName: (item: string) => void;
};

export const useSearchStore = createStore<SearchStore>((set) => ({
  // state
  currentRecommend: [],
  totalFilter: 0,
  popularCourse: [],
  searches: [],
  keywords: [],
  indexName: "",

  // actions
  setRecommendData(item) {
    set({ currentRecommend: item });
  },
  setTotalFilter(filter) {
    set({ totalFilter: filter });
  },
  setPopularCourse(item) {
    set({ popularCourse: item });
  },
  setSearches(item) {
    set({ searches: item });
  },
  setKeywords(item) {
    set({ keywords: item });
  },
  setIndexName(item) {
    set({ indexName: item });
  },
}));
