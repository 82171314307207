import ModalPortal from './ModalPortal';

type ModalProps = {
  children: React.ReactNode;
  closeModal?: () => void;
  show?: Boolean;
  hide?: Boolean;
  isCenter?: Boolean;
  isBottom?: Boolean;
  zIdx?: number;
  top?: string;
  isFull?: Boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeModal,
  show = true,
  isCenter = false,
  isBottom = false,
  zIdx = 0,
  isFull = false,
  hide = false,
  top = '0',
}) => {
  if (!show) return null

  const modal_style = {
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    zIndex: 1001,
  } as React.CSSProperties;

  const modal_style_bottom = {
    position: 'fixed',
    transform: 'translateX(-50%)', // Only translate horizontally
    bottom: '0', // Position at the bottom
    left: '50%', // Center horizontally
    zIndex: 1001,
  } as React.CSSProperties;

  const modal_style_top = {
    position: 'fixed',
    transform: 'translateX(-50%)', // Only translate horizontally
    top, // Position at the top
    left: '50%', // Center horizontally
    zIndex: 1001,
  } as React.CSSProperties;
  
  const overlay = {
    zIndex: zIdx
  } as React.CSSProperties;

  const style = "w-full"

  return (
    <ModalPortal>
      <>
        {/* Modal content */}
        <div
          onClick={closeModal} style={overlay}
          className={`bg-black opacity-40 top-0 bottom-0 right-0 left-0 fixed ${hide ? "hidden" : ""}`}
        >
        </div>
        <div
          style={isCenter ? modal_style : isBottom ? modal_style_bottom : modal_style_top}
          className={`${isFull ? style : ""} ${hide ? "hidden" : ""}`}
        >
          {children}
        </div>
      </>
    </ModalPortal>
  );
};

export default Modal;
