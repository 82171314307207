import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { useState, useMemo } from "react";
import { InstantSearch } from "react-instantsearch-dom";
import SearchBox from "./SearchBox";
import algoliasearch from "algoliasearch";
import { CONST } from "lib/const";
import { useIsDesktop } from "lib/device";

export type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  events?: any;
  placeholder?: string;
  position?: "right" | "left";
  focus?: boolean;
  contentClass?: string;
  onBackHeader?(): void;
  theme?: string;
  indexName?: string;
};


const searchClient = algoliasearch(
  CONST.ALGOLIA_APP_ID,
  CONST.ALGOLIA_SEARCH_KEY
);

const InstantSearchBox: React.FC<Props> = ({ indexName, ...props }) => {
    return (
      <div className="relative">
        <InstantSearch
          searchClient={searchClient}
          indexName={indexName ?? CONST.ALGOLIA_SEARCH_INDEX}
        >
          <div className="grid gap-8 relative">
            <SearchBox
              {...props}
              indexName={indexName}
              className={props.className ?? ""}
            />
          </div>
        </InstantSearch>
      </div>
    );
};

export default InstantSearchBox;
