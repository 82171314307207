import Modal from "components/Modal";
import InstantSearchBox from "./InstantSearchBox";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useIsTenant } from "lib/host";

export const ModalSearchBox: React.FC<{
  title?: string;
  isShow?: boolean;
  onClose?(): void;
  indexName?: string;
}> = ({ isShow, onClose, title, indexName, ...props }) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  return (
    <Modal
      // zIndex={10}
      // isSearch={isShow}
      isShow={isShow}
      contentClassName="!rounded-0"
      className="!bottom-auto"
    >
      <div className="w-full h-screen">
        <InstantSearchBox
          indexName={indexName}
          placeholder={
            useIsTenant() ? t("searchCoursesTruncate") : t("searchCourses")
          }
          position="left"
          className="w-full"
          contentClass="!shadow-none !p-0 h-fit"
          focus={isShow}
          events={{
            event: "explore_searchbar_a",
            property: {
              page: router.pathname,
            },
          }}
          onBackHeader={onClose}
        />
      </div>
    </Modal>
  );
};
