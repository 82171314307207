import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type ModalPortalProps = {
  children: React.ReactNode;
};

const ModalPortal: React.FC<ModalPortalProps> = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  if (typeof document === 'undefined' || !mounted) return null;

  const modalRoot = document.getElementById('modal');

  return ReactDOM.createPortal(children, modalRoot);
};

export default ModalPortal;

