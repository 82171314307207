import { Image } from "components/Image/Image";
import { toDuration } from "lib/date";
import { paths } from "lib/__generatedApiSpec";
import { OpReturnType } from "openapi-typescript-fetch/dist/cjs/types";
import Images from "next/image";
import Badge from "components/Badge";
import { IconStar } from "components/Icon/IconStar";
import { Course } from "./interfaces";
import Button from "components/Button";
import { formatRating, isCustomDomain, numberWithCommas } from "lib/helper";
import { useIsDesktop } from "lib/device";
import { LogoChip } from "components/Chip/LogoChip";
import Tooltip from "components/Tooltip";
import { IconTimer } from "components/Icon/IconTimer";
import { IconCheck } from "components/Icon/IconCheck";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { trackEvent } from "lib/amplitude";
import { Chip } from "components/Chip/Chip";
import { useIsBusiness, useIsSubdomain, useIsTenant } from "lib/host";
import { IconPlusCircle } from "components/Icon/IconPlusCircle";
import { IconCheckCircle } from "components/Icon/IconCheckCircle";
import { useCartStore } from "modules/Business/Selection/cartStore";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { IconPlayCircle } from "components/Icon/IconPlayCircle";

type PropsCard = Course & {
  isUncheck?: boolean;
  isHide?: boolean;
};

//@ts-ignore
export const CardCourse: React.FC<PropsCard> = ({
  isLayoutNew = false,
  className = "",
  isUncheck = false,
  isHide = false,
  ...props
}) => {
  const isSubdomainTenant = useIsSubdomain();
  const isDesktop = useIsDesktop();
  const [timeout, setModalTimeout] = useState(null);
  const [checkedCard, setCheckedCard] = useState([]);
  const isTenatURL = useIsTenant();
  const isB2B = useIsTenant() || useIsSubdomain();
  const isBusiness = !isB2B;

  const updateCart = useCartStore((state) => state.updateCart);

  let institutionImage =
    //@ts-ignore
    props?.institution?.image_url
      ? //@ts-ignore
        props?.institution?.image_url
      : typeof props?.institution == "string" &&
        //@ts-ignore
        props?.institution?.split("||").length > 1 &&
        //@ts-ignore
        props?.institution?.split("||")[1].includes("https")
      ? //@ts-ignore
        props?.institution?.split("||")[1]
      : //@ts-ignore
      props?.institution && typeof props?.institution == "object"
      ? //@ts-ignore
        props?.institution?.image_url
      : null;

  let institutionName =
    //@ts-ignore
    props.institution?.name || props.institution?.split("||")[0] || "";

  const classHeight =
    className == "card-my-class"
      ? "112px"
      : className == "card-default"
      ? "164px"
      : "171px";

  const wCustom = props?.isHorizontal ? 287 : 328;
  const hCustom = props?.isHorizontal ? 164 : 228;

  const hasUserPaid: boolean = (props as any).is_paid;
  const isCourseFree: boolean =
    props.original_price === 0 || props.discounted_price === 0;

  const isCourseFinished =
    props.enrollment_progress?.finished === props.enrollment_progress?.total;

  const router = useRouter();

  const events = {
    event: "course_hover_a",
    property: {
      course_name: props?.name ?? "",
      course_slug: props?.slug ?? "",
      section_name: props?.sectionName ?? "",
      institution_name:
        //@ts-ignore
        props?.institution_name ?? props.institution?.name ?? "",
      index: props?.index ?? 0,
      page: router.pathname,
    },
  };

  const { t } = useTranslation("common");

  if (isLayoutNew) {
    return (
      <div
        className={`w-full overflow-hidden cursor-pointer ${
          className == "card-my-class"
            ? "rounded-4"
            : className == "card-hidden"
            ? "!opacity-0"
            : "rounded-8"
        }`}
        onClick={() => {
          //@ts-ignore
          if (props.onSelect) {
            //@ts-ignore
            // props.onSelect();
            updateCart(props);
          }
        }}
      >
        <div
          className="group relative inline-block w-full h-full flex flex-col flex-wrap"
          onMouseEnter={
            isBusiness
              ? undefined
              : () => {
                  timeout && clearTimeout(timeout);
                  setModalTimeout(
                    setTimeout(() => {
                      trackEvent({
                        ...(events as any),
                      });
                    }, 3000)
                  );
                }
          }
          onMouseLeave={
            isBusiness
              ? undefined
              : () => {
                  timeout && clearTimeout(timeout);
                }
          }
        >
          <div className="relative group-hover:h-fit">
            {props.isHorizontal ? (
              <>
                {props.image_cover_url ? (
                  <Image
                    src={props.image_cover_url}
                    width={wCustom}
                    height={hCustom}
                    data-testid="card-featured_cover"
                    className="object-cover w-full h-full object-center"
                    style={{
                      borderRadius:
                        className == "card-my-class"
                          ? "4px 4px 0 0"
                          : "8px 8px 0 0",
                    }}
                  />
                ) : (
                  <div
                    className={
                      "bg-neutral-20" +
                      (props?.isHorizontal
                        ? `${
                            className == "card-my-class"
                              ? " h-[112px]"
                              : ` ${
                                  isUncheck
                                    ? "h-[156px]"
                                    : "h-[164px] xl-max:h-[100px]"
                                }`
                          } w-full`
                        : " h-[228px] w-full")
                    }
                    style={{
                      borderRadius:
                        className == "card-my-class"
                          ? "4px 4px 0 0"
                          : "8px 8px 0 0",
                    }}
                  ></div>
                )}
              </>
            ) : (
              <>
                {props.image_cover_url ? (
                  <Image
                    src={props.image_cover_url}
                    className={
                      "object-cover w-full object-center" +
                      ` h-[${isDesktop ? "171" : classHeight}px]`
                    }
                    style={{
                      borderRadius:
                        className == "card-my-class"
                          ? "4px 4px 0 0"
                          : "8px 8px 0 0",
                    }}
                  />
                ) : (
                  <div
                    className={
                      "bg-neutral-20 w-full h-[171px] xl-max:h-[144px]"
                    }
                    style={{
                      borderRadius:
                        className == "card-my-class"
                          ? "4px 4px 0 0"
                          : "8px 8px 0 0",
                    }}
                  ></div>
                )}
              </>
            )}

            {!isBusiness && (
              <div
                className={"absolute top-0 w-full h-full group-hover:hidden"}
              >
                <div className="h-full flex justify-center items-center">
                  {/* <Images
                    src={"/images/icon-play-grey.svg"}
                    alt="playGrey"
                    width={45}
                    height={45}
                  /> */}
                  <IconPlayCircle size={45} classes="m-auto" isBgCustomized />
                </div>
              </div>
            )}
            {/* @ts-ignore */}
            {isBusiness && router.pathname == "/pilih-kursus" && !isUncheck && (
              <div
                className={
                  "absolute top-0 w-full h-full flex justify-end items-start p-8"
                }
              >
                <IconPlusCircle />
              </div>
            )}

            {/* {!isBusiness && !isSubdomainTenant && !isTenatURL && (
              <div
                className={
                  "absolute bottom-0 w-full flex justify-end pr-8 pb-8 " +
                  (className === "card-institutions" && !isDesktop
                    ? "hidden"
                    : "")
                }
              >
                <Badge styles="text-white !rounded-16 bg-gradient-to-r from-green-70 to-green-90 px-8 text-b2 flex justify-center items-center">
                  {t("free").toUpperCase()}
                </Badge>
              </div>
            )} */}
          </div>

          <div className="h-[116px] bg-purple-70">
            <div
              className={`w-full h-full group-hover:absolute group-hover:bottom-0 group-hover:flex group-hover:flex-col group-hover:justify-between`}
            >
              <div className="h-[-webkit-fill-available] hidden justify-center items-center group-hover:flex">
                {!isBusiness && (
                  <Images
                    src={"/images/icon-play-grey.svg"}
                    alt="playGrey"
                    width={45}
                    height={45}
                  />
                )}
              </div>
              <div
                className={`gap-8 relative bg-purple-70 h-full text-white py-14 px-16 items-center transition-height duration-500 ease-in-out absolute`}
              >
                <div
                  className={
                    "flex flex-col justify-between h-full " +
                    (className == "card-institutions" ? "gap-4" : "gap-8")
                  }
                >
                  <h3 className="text-b1 font-medium max-w-full line-clamp-2 text-white text-left">
                    {props.name}
                  </h3>

                  <div className="flex gap-8 items-center">
                    {institutionImage && (
                      // <LogoChip image_url={institutionImage} size={36} />
                      <LogoChip
                        image_url={institutionImage}
                        size={40}
                        padding={4}
                        useResizeImage={true}
                      />
                    )}

                    <div className="grid gap-4">
                      <div className="text-c1 font-light max-w-full truncate text-left">
                        {institutionName}
                      </div>

                      <div className="flex gap-8 justify-start items-center text-c1 font-light">
                        {/* @ts-ignore */}
                        {typeof props?.review_summary?.star_avg === "number" &&
                        //@ts-ignore
                        props?.review_summary?.star_avg !== 0 &&
                        !isB2B ? (
                          <>
                            <div className="flex items-center justify-center">
                              <IconStar size={14} fill color="#EFB443" />
                              <div className="ml-2 text-c1">
                                {formatRating(
                                  //@ts-ignore
                                  props?.review_summary?.star_avg.toFixed(1)
                                )}
                              </div>
                            </div>
                            |
                          </>
                        ) : (
                          <></>
                        )}
                        {props.total_learners !== 0 && !isB2B && (
                          <div>{`${numberWithCommas(props.total_learners)} ${t(
                            "pelajar"
                          ).toLowerCase()}`}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="hidden group-hover:inline-block mt-2">
                    {isBusiness ? (
                      <Link
                        className="hidden w-full group-hover:inline-block mt-2"
                        // @ts-ignore
                        href={`https://www.gokampus.com/course/${props.slug}`}
                        passHref
                        // target="__blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="white_transparant" className="w-full">
                          Lihat Kursus
                        </Button>
                      </Link>
                    ) : (
                      <>
                        {props.skills.length > 0 ? (
                          <>
                            <h5 className="text-c1 font-medium mb-8 text-left">
                              {t("skills")}
                            </h5>

                            <div className="flex flex-wrap gap-12 items-center">
                              {props?.skills?.slice(0, 3).map((skill) => (
                                <Chip
                                  key={skill.slug}
                                  className="text-purple-50"
                                >
                                  {skill.name || skill.toString()}
                                </Chip>
                              ))}

                              {props?.skills.length > 3 && (
                                <div className="text-white text-c1 font-medium">
                                  +{props.skills.length - 3} Skill lainnya
                                </div>
                              )}
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>

                {className == "card-my-class" && (
                  <div className="text-b2 pt-4">
                    {isCourseFinished &&
                    (!hasUserPaid || isCourseFree) ? null : isCourseFinished ? (
                      <Button
                        variant="secondary_white"
                        size="medium"
                        isFull
                        className="px-0"
                      >
                        Download Sertifikat
                      </Button>
                    ) : props.enrollment_progress?.finished === 0 ? (
                      <Button variant="secondary_white" size="medium" isFull>
                        Mulai Kelas
                      </Button>
                    ) : props.enrollment_progress?.finished <
                      props.enrollment_progress?.total ? (
                      <div className="flex h-36 items-center">
                        <div className="text-b2">
                          {props.enrollment_progress.finished} dari{" "}
                          {props.enrollment_progress.total}
                        </div>
                        <div className="w-8" />
                        <div className="flex-1 h-8 bg-neutral-70 rounded-full overflow-hidden relative">
                          <div
                            className="rounded-full h-full bg-white"
                            style={{
                              width:
                                (props.enrollment_progress.finished /
                                  props.enrollment_progress.total) *
                                  100 +
                                "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* @ts-ignore */}
          {props.isChecked ? (
            <div className="absolute bg-[#0d0d0d80] top-0 rounded-8 w-full h-full p-8 flex justify-end items-start">
              <IconCheckCircle />
            </div>
          ) : null}

          {isHide && (
            <div className="absolute top-0 bg-[#00000099] w-full h-full flex justify-center items-center">
              <div className="text-b1 font-black text-white">
                Khusus goKampus Business
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full rounded-2 overflow-hidden">
        <div className="h-64 bg-neutral-20 overflow-hidden">
          <Image
            width={160}
            height={64}
            src={props.image_cover_url}
            alt={props.name}
            className="h-64 w-full object-cover object-center"
          />
        </div>
        <div className="h-[172px] bg-purple-70 p-8 pt-0 text-white flex flex-col">
          <div className="flex-1">
            <div className="w-48 h-48 rounded-2 -mt-24 bg-white p-6">
              <Image
                width={36}
                height={36}
                src={props.institution?.image_url}
                alt={props.institution?.name}
                className="object-contain object-center"
              />
            </div>
            <div className="h-8" />
            <div className="text-c1 font-light line-clamp-1">
              {props.institution?.name}
            </div>
            <div className="h-2" />
            <div className="text-b2 font-bold line-clamp-2">{props.name}</div>
            <div className="h-8" />
            <div className="text-c1 font-light line-clamp-1">
              {props.total_learners && !isB2B
                ? `${numberWithCommas(props.total_learners)} pelajar | `
                : null}
              {props.duration == 0 ? null : toDuration(props.duration, false)}
            </div>
          </div>
          {/* <div className="text-b2">GRATIS</div> */}
        </div>
      </div>
    );
  }
};
