import React, { useContext } from "react";
import Link from "next/link";

import { IconArrow } from "../Icon/IconArrow";
import type { Route } from "nextjs-routes";

export const BackButton: React.FC<{ size?: number; backTo?: any }> = ({
  size = 24,
  backTo = "",
}) => {
  return (
    <a onClick={backTo} className="cursor-pointer">
      <IconArrow size={size} />
    </a>
  );
};
