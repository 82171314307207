import { IconSearch } from "components/Icon/IconSearch";
import { ChangeEvent, useEffect, useState } from "react";
import { connectSearchBox, Configure, Index } from "react-instantsearch-dom";
import { ClipLoader } from "react-spinners";
import React from "react";
import { useIsDesktop } from "lib/device";
import { trackEvent } from "lib/amplitude";
import { useRouter } from "next/router";
import { CONST } from "lib/const";
import SearchHitsCourse from "./SearchHitsCourse";
import { BackButton } from "components/PageHeader/BackButton";
import Modal from "components/ModalPortal/Modal";
import SearchResultsLms from "./SearchResultsLms";
import { useIsSubdomain, useIsTenant } from "lib/host";
import { FilteredKeys } from "lib/object";
import IconClose from "components/Icon/IconClose";
import SearchHitsKeyword from "./SearchHitsKeyword copy";
import { useSystemStore } from "components/PageHeader/systemStore";

const useOutsideClick = (callback: () => void) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !(ref.current as any)?.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [ref]);

  return ref;
};

function SearchBox({
  currentRefinement,
  refine,
  className,
  indexName,
  ...props
}) {
  const router = useRouter();
  const query = (router && router.query.q) || null;
  const [theme] = useSystemStore((state) => [state.theme]);

  /// read value from path param of q
  const [value, setValue] = useState(currentRefinement || query);
  const [timeout, setTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = typeof window !== "undefined" && window.innerWidth > 720;
  const [show, setShow] = useState(false);
  const [showLms, setLms] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const isLMS = router.pathname.includes("/course/[courseSlug]");
  const isB2B = useIsTenant() || useIsSubdomain();
  const tagsKey = (router.query.tagsKey as any) ?? "";
  const institutionKey = (router.query.institutionKey as any) ?? "";

  const q = router?.query?.q ?? "";
  const institutionNames =
    typeof router.query.institutionKey == "string" &&
    router.query.institutionKey
      ? router.query.institutionKey.split(",")
      : [];
  const institutionKeyFilter = institutionNames.length
    ? `${institutionNames.map((ins) => `institution:"${ins}"`).join(" OR ")}`
    : "";

  const tagsNames =
    typeof router.query.tagsKey == "string" && router.query.tagsKey
      ? router.query.tagsKey.split(",")
      : [];
  const tagsKeyFilter = tagsNames.length
    ? `${tagsNames.map((ins) => `tags:"${ins}"`).join(" OR ")}`
    : "";
  const isEnteredSearch = tagsKeyFilter !== "" || institutionKeyFilter !== "";
  const isAnyFiltered = q !== "";
  const handleReset = () => {
    setValue("");
    router.push({
      pathname: "/",
    });
  };

  const themes = {
    default: "placeholder-input_placeholder bg-white text-neutral-50 ",
    white: "placeholder-white bg-[#ffffff33] text-white ",
    transparent:
      "placeholder-input_placeholder bg-transparent text-neutral-50 ",
  };

  const themeIcon = {
    default: "#5D586C ",
    white: "#FFFFFF ",
    transparent: "#44199A",
  };

  useEffect(() => {
    if (!q) {
      setValue("");
    }
  }, [q]);

  const ref = useOutsideClick(() => {
    setShow(false);
  });

  const onChangeDebounced = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (value === "") {
      setShow(true);
      setLms(false);
      setShowBtn(false);
    }

    timeout && clearTimeout(timeout);

    if (value) {
      setIsLoading(true);
    } else {
      // only push route for desktop view
      // No need to change path since this makes
      // LMS page move to homepage when activated (23/7/24)
      // if (isB2B && window.screen.availWidth > 900) {
      //   router.push({
      //     pathname: "/",
      //   });
      // }
    }

    setTime(
      setTimeout(() => {
        refine(value);
        setIsLoading(false);
      }, 1000)
    );

    /// No need to redirect since algolia already return
    /// recommendation after delete the form in search box
    // if(isB2B && !value && isDesktop && !isLMS) {
    //   router.push({
    //     pathname: "/",
    //   });
    // }
    setValue(value);
  };

  useEffect(() => {
    if (props.focus) {
      setShow(true);
    }
  }, [props]);

  const toExplorePage = () => {
    router.push({
      pathname: "/explore/search",
      query: {
        q: value,
      },
    });
    setShow(false);
  };

  const toHomeTenantPage = () => {
    if (tagsKey || institutionKey) {
      const filteredQuery = FilteredKeys(
        ["tagsKey", "institutionKey"],
        router.query
      );
      router.push({
        pathname: "/",
        query: {
          ...filteredQuery,
          q: value,
        },
      });
    } else {
      router.push({
        pathname: "/",
        query: value
          ? {
              ...router.query,
              q: value,
            }
          : {
              ...router.query,
            },
      });
    }

    setShow(false);
  };

  const handleKeyPress = (event) => {
    if (
      (event.charCode == 13 || event.key == "Enter") &&
      event.target.value !== ""
    ) {
      event.preventDefault();

      trackEvent({
        event: "explore_searchbar_a",
        property: {
          query: value,
          page: router.pathname,
        },
      });

      if (isDesktop && isLMS) {
        event.target.blur();
        setShow(false);

        /// Directly redirect user to search page
        /// Instead of showing popup result 4 recommendation courses

        // setLms(true);
        if (isB2B) {
          toHomeTenantPage();
        } else {
          toExplorePage();
        }
        return;
      }

      if (isLMS && !isDesktop) {
        event.target.blur();
        /// Directly redirect user to search page
        /// Instead of showing popup result 4 recommendation courses

        // setShowBtn(true);
        if (isB2B) {
          toHomeTenantPage();
        } else {
          toExplorePage();
        }
        return;
      }

      if (isB2B) {
        toHomeTenantPage();
      } else {
        toExplorePage();
      }
    }
  };

  const renderResult = () => {
    let courseSuggestions = 3;
    if (showBtn) courseSuggestions = 4;

    return (
      <div
        className={`${
          props.contentClass ?? ""
        } z-50 block md:bg-slated_grey p-16 grid gap-12 rounded-12 shadow-dropdown absolute pointer-events-auto z-200 w-full h-fit top-50 `}
      >
        <Index indexName={indexName ?? CONST.ALGOLIA_SEARCH_INDEX}>
          <Configure hitsPerPage={courseSuggestions} />
          <SearchHitsCourse
            searchValue={value}
            showBtn={showBtn}
            onClickEnter={isB2B ? toHomeTenantPage : toExplorePage}
            closeModal={props.onBackHeader}
            indexName={indexName}
          />
        </Index>

        {!showBtn && (
          <SearchHitsKeyword
            indexName={indexName}
            onClickKeyword={() => setShow(false)}
            onSetValue={(keyword) => setValue(keyword)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="relative flex gap-12 items-center" ref={ref}>
      {props.onBackHeader && <BackButton backTo={() => props.onBackHeader()} />}

      {props.position == "left" && (
        <div
          // className={`absolute right-0 flex items-center bg-white_to_dark20 ${
          //   props.onBackHeader ? "left-48 z-20 mr-2" : "left-10 z-10"
          // }`}
          className={`absolute top-10 ${
            props.onBackHeader ? "left-48 z-20 mr-2" : "left-10 z-10"
          }`}
          // style={{
          //   height: "100%",
          //   padding: "10px",
          //   borderTopRightRadius: "10px",
          //   borderBottomRightRadius: "10px",
          // }}
        >
          {isLoading ? (
            <ClipLoader size={16} color="black" />
          ) : (
            <>
              {isAnyFiltered ? (
                <div onClick={handleReset} className="cursor-pointer">
                  <IconClose size={20} color="#551FC1" />
                </div>
              ) : (
                <IconSearch
                  size={20}
                  color={theme === "dark" ? "#817E8C" : "#551FC1"}
                  // color={themeIcon[props.theme ?? "default"]}
                />
              )}
            </>
          )}
        </div>
      )}

      <input
        className={`${className} ${
          props.position == "left"
            ? props.onBackHeader
              ? "pl-40"
              : "pl-32"
            : ""
        } relative p-8 text-b1 font-light border border-neutral40_to_dark20 rounded-8 ${
          themes[props.theme ?? "default"]
        } focus:outline focus:outline-2 focus:outline-purple-50 text-ellipsis`}
        placeholder={props?.placeholder ?? "Search..."}
        onChange={(e) => {
          onChangeDebounced(e);
        }}
        onKeyPress={handleKeyPress}
        onFocus={() => {
          trackEvent({
            event: "explore_searchbar_start_a",
            property: {
              page: router.pathname,
            },
          });
          setShow(true);
          setLms(false);
        }}
        value={value}
        autoFocus={props.focus ?? false}
      />

      {props?.position == "right" && (
        <div
          // className={"absolute top-10 right-10"}
          className={
            "absolute right-0 flex items-center bg-purple05_to_dark20 border border-neutral40_to_dark20"
          }
          style={{
            height: "100%",
            padding: "10px",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          {isLoading ? (
            <ClipLoader size={16} color="fill-neutral-50" />
          ) : (
            <>
              {isAnyFiltered ? (
                <div onClick={handleReset} className="cursor-pointer">
                  <IconClose
                    size={20}
                    color={theme === "dark" ? "#817E8C" : "#551FC1"}
                  />
                </div>
              ) : (
                <IconSearch
                  size={20}
                  color={theme === "dark" ? "#817E8C" : "#551FC1"}
                  // color={themeIcon[props.theme ?? "default"]}
                />
              )}
            </>
          )}
        </div>
      )}

      {show && <>{renderResult()}</>}

      {showLms && (
        <Modal isFull closeModal={() => setLms(false)}>
          <Index indexName={indexName ?? CONST.ALGOLIA_SEARCH_INDEX}>
            <Configure hitsPerPage={4} />
            <SearchResultsLms
              searchValue={value}
              onClickEnter={isB2B ? toHomeTenantPage : toExplorePage}
              closeModal={() => setLms(false)}
            />
          </Index>
        </Modal>
      )}
    </div>
  );
}
export default connectSearchBox(SearchBox);
