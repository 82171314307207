import { useApi } from "lib/apiFetcher";

export const useExploreCourseCategories = () =>
  useApi("get", "/course-categories");

export const useExploreCourseInstitutions = () =>
  useApi("get", "/course-institutions");

export const useInstitutionsTenant = () =>
  useApi("get", "/tenants/{slug}/institutions");

export const useAccessCourseTenant = () =>
  useApi("get", "/tenants/{slug}/access-courses");

export const useExploreCompetences = () =>
  useApi("get", "/course-competences");

export const useCompetenciesTenant = () =>
  useApi("get", "/tenants/{slug}/competencies");

export const useGetCoursesByCategory = () =>
  useApi("get", "/course-institutions/{slug}");
