import { connectStateResults, connectHits } from "react-instantsearch-dom";
import { Course } from "components/Card/interfaces";
import { CardNotFound } from "components/Card/CardNotFound";
import { CardCourse } from "components/Card/CardCourse.legacy";
import { ContainerDesktop } from "components/Container";
import IconClose from "components/Icon/IconClose";
import Link from "next/link";
import { useAuthStore } from "modules/Auth/authStore";
import { trackEvent } from "lib/amplitude";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useIsSubdomain, useIsTenant } from "lib/host";

function SearchResultsLms({ searchState, searchResults, ...props }) {
  // checking if the query length is >= 3
  // (since 3 is the minimum Algolia query length)
  const validQuery = searchState.query?.length >= 3;
  const loginState = useAuthStore((state) => state.loginState);
  const totalData = searchResults?.nbHits
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [allCourses, setAllCourses] = useState(null);
  const isB2B = useIsTenant() || useIsSubdomain();

  const dataCourses =
    searchResults?.hits.length > 0 && searchState.query && validQuery
      ? searchResults.hits
      : searchResults?.hits.length > 0 && !searchState.query
      ? searchResults.hits
      : null;

  useEffect(() => {
    if (dataCourses && dataCourses.length > 0) {
      const newData = isB2B
        ? dataCourses
        : dataCourses.filter((a) => !a.is_institution_business);
      if (newData && newData.length > 0) {
        setAllCourses(newData);
      } else {
        setAllCourses(null); 
      }
    } else {
      setAllCourses(null);
    }
  }, [dataCourses]);


  const searchResultsTitle = (
    <div className="flex items-center py-20">
      <div onClick={props.closeModal} className="text-neutral-80 mr-16 cursor-pointer">
          <IconClose size={20}/>
      </div>
      {
        props.searchValue && (
          <div className="flex items-center text-b1 font-medium text-neutral-60">
            {
              allCourses && allCourses.length > 0 && (
                <span>
                  {allCourses.length}
                </span>
              )
            }
            <p className="mx-4">
                Hasil Pencarian untuk
            </p>
            <span className="text-neutral-80">
              "{props.searchValue}"
            </span>
          </div>
        )
      }
    </div>
  )

  return (
    <div className="absolute top-[71px] w-full bg-white rounded-b-16">
      {!allCourses ? (
        <ContainerDesktop>
          {searchResultsTitle}

          <CardNotFound
            title="Kursus Tidak Ditemukan"
            message="Coba cari kursus dengan kata kunci lain"
            className="!gap-16 pb-24"
          />
        </ContainerDesktop>
      ) : (
        <ContainerDesktop>
            <div className="flex items-center justify-between">
                {searchResultsTitle}

                

                <p onClick={() => {
                  trackEvent({
                    event: "explore_searchbar_srp_course_more_a",
                    property: {
                      page: router.pathname,
                      query: props.searchValue
                    }
                  })
                  props.onClickEnter()
                }} className="pr-16 text-purple-50 text-b1 font-medium cursor-pointer">Lihat Semua Hasil</p>
            </div>
            <div className="grid grid-cols-4 gap-8 pb-24">
              {allCourses.map((hit: Course, index: any) => (
                  // @ts-ignore
                  <Link
                    href={
                      loginState == "LOGIN"
                        ? 
                          {
                            pathname: "/r/[page]",
                            query: {
                              page: `/course/${hit?.slug}`,
                            },
                          }
                        : {
                            pathname: "/login",
                            query: {
                              url: encodeURIComponent(`/course/${hit?.slug}`),
                            },
                          }
                    }
                    onClick={() => {
                      trackEvent({
                        event: "explore_searchbar_srp_course_a",
                        property: {
                          page: router.pathname,
                          query: props.searchValue,
                          course_name: hit.name,
                          course_slug: hit.slug,
                          institution_name: hit?.institution?.split('||')[0],
                          index,
                        },
                      })
                      props.closeModal()
                    }}
                  >
                    <CardCourse
                    key={hit.id}
                    {...{ ...hit, index }}
                    isLayoutNew
                    isHorizontal
                    className="card-default"
                    />
                  </Link>
              ))}
            </div>
        </ContainerDesktop>
      )}
    </div>
  );
}

export default connectStateResults(SearchResultsLms);
