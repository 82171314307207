import Image from "next/image";
import Button from "components/Button";
import { trackEvent } from "lib/amplitude";
import Link from "next/link";
import { useRouter } from "next/router";

interface Props {
  title?: string;
  message?: string;
  useButton?: BProps[];
  src?: string;
  className?: string;
  useSmallIcon?: boolean;
  useMaxWidth?: number;
}

interface BProps {
  label?: string;
  href?: any;
  variant?: string;
  onClose?(): void;
}

export const CardNotFound: React.FC<Props> = ({
  useSmallIcon = false,
  useMaxWidth = 0,
  ...props
}) => {
  const router = useRouter();
  const { title, message, useButton, src, className = "" } = props;

  const pushToTrackEvents = (label: string) => {
    switch (label) {
      case "Mulai Belajar Gratis":
        trackEvent({
          event: "myclass_startlearning_a",
        });
        break;

      case "Kembali ke Homepage":
        trackEvent({
          event: "error_404_back_to_homepage_a",
        });
        break;

      case "Coba Lagi":
        trackEvent({
          event: "error_500_retry_a",
        });
        router.reload()
        break;

      case "Kembali":
        trackEvent({
          event: "error_500_back_a",
        });
        router.back()
        break;

      default:
        break;
    }
  };

  return (
    <div
      className={`w-full flex ${useSmallIcon ? "flex-row justify-left" : "flex-col justify-center"} text-center gap-24 items-center m-auto ${className} ${useMaxWidth ? "bg-neutral-10 min-h-[320px] rounded-8" : ""}`}
    >
      <Image
        src={src ? src : "/images/icon-not-found.png"}
        alt=""
        width={useSmallIcon ? 80 : 120}
        height={useSmallIcon ? 80 : 120}
      />

      <div className={`flex flex-col gap-24 ${useMaxWidth ? `max-w-[${useMaxWidth}px] px-16 md:px-0`:""}`}>
        <div className={`${useSmallIcon ? "grid align-center gap-4" : "flex flex-col gap-8"}`}>
          {title && <div className={`text-b1 font-bold ${useSmallIcon ? "text-left" : ""}`}>{title}</div>}
          {message && <div className={`text-b2 font-medium text-neutral-60 ${useSmallIcon ? "text-left" : ""}`}>{message}</div>}
        </div>

        {useButton && useButton.length > 0 ? (
          <div
            className={
              "flex justify-center " + (useButton.length > 1 ? "gap-8" : "")
            }
          >
            {useButton.map((el) => (
              <Link
                href={el.href}
                onClick={() => {
                  pushToTrackEvents(el.label);
                  if (el.onClose) {
                    el.onClose();
                  }
                }}
                key={"useButton" + el.href}
              >
                <Button
                  size="medium"
                  isFull
                  //@ts-ignore
                  variant={el.variant ? el.variant : "primary"}
                >
                  {el.label}
                </Button>
              </Link>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
