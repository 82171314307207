import React, { DetailedHTMLProps, InputHTMLAttributes, useState } from "react";

import { IconSearch } from "components/Icon/IconSearch";
import { trackEvent } from "lib/amplitude";
import { useRouter } from "next/router";
import { ModalSearchBox } from "components/InstantSearchBox/ModalSearchBox";

export type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  text?: string;
  onChangeSearch?: (text: any) => void;
  events?: any;
  placeholder?: string;
  position?: "right" | "left";
  theme?: "default" | "white";
  handleClick?: () => void;
  submitSearch?: () => void;
  setModal?: (a: boolean) => void;
  isShow?: boolean;
  indexName?: string;
  classWidth?: string;
};

const FieldSearch: React.FC<Props> = ({
  onChangeSearch,
  text,
  placeholder = "Ingin belajar apa hari ini?",
  position = "left",
  theme = "default",
  handleClick,
  submitSearch,
  indexName,
  isShow,
  setModal,
  classWidth = "w-600 x-max:w-400",
}) => {
  const router = useRouter();
  const themes = {
    default: "placeholder-input_placeholder bg-white text-neutral-50 ",
    white: "placeholder-white bg-[#ffffff33] text-white ",
  };

  const themeIcon = {
    default: "#5D586C ",
    white: "#FFFFFF ",
  };

  const themeBorder = {
    default: "border border-neutral-40 ",
    white: "border-none outline-none ",
  };

  return (
    <div className={"relative  " + (classWidth ?? "")}>
      <div
        className={
          "absolute top-10 " + (position == "left" ? "left-10" : "right-10")
        }
      >
        <IconSearch size={20} color={themeIcon[theme]} />
      </div>

      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          onFocus={() => {
            trackEvent({
              event: "explore_searchbar_start_a",
              property: {
                page: router.pathname,
              },
            });
            setModal(true);
          }}
          className={
            `w-full focus:outline-none p-8 text-b1 font-light rounded-6 ${themes[theme]} ${themeBorder[theme]} ` +
            (position == "left" ? " pl-36" : " pr-36")
          }
          placeholder={placeholder}
        />
        <input type="hidden" />
      </form>

      <ModalSearchBox
        isShow={isShow}
        indexName={indexName}
        onClose={() => {
          setModal(false);
          router.push({
            pathname: "/",
          });
        }}
      />
    </div>
  );
};

export default FieldSearch;
